import api from "../api";
/**
 * 管理员用户相关api
 */
export class AdminInfoApi extends api {
  /**
   * 获取分页数据
   * @param {*} 分页请求数据
   */
  getRolePage({
    pageInfo,
    condition
  }) {
    return this.request({
      url: `${this.servicePath}/${this.modulePath}/rolePage`,
      method: 'post',
      pageInfo,
      data: condition
    })
  }
}

export default new AdminInfoApi('adminInfo')