<template>
  <el-form size="mini" ref="form" :model="admin" :rules="formRules" label-width="120px" status-icon>
    <el-form-item label="账号" prop="account">
      <el-input v-model="admin.account" placeholder="请输入管理员账号" :disabled="id !== undefined && id.length > 0"></el-input>
    </el-form-item>
    <el-form-item label="名称" prop="name">
      <el-input v-model="admin.name" placeholder="请输入管理员名称"></el-input>
    </el-form-item>
    <el-form-item v-if="!id" label="密码" prop="password">
      <el-input type="password" placeholder="请输入管理员密码" v-model="admin.password" auto-complete="new-password" readonly onfocus="this.removeAttribute('readonly')"></el-input>
    </el-form-item>
    <el-form-item label="启用" prop="state">
      <el-switch v-model="state" :active-value="1" :inactive-value="0"></el-switch>
    </el-form-item>
    <el-form-item label="角色">
      <el-button type="text" @click="handleSelectRoles">添加角色</el-button>
      <el-table ref="dataTable" stripe border fit size="mini" highlight-current-row :data="admin.roles" style="width: 100%">
        <el-table-column type="index" width="50"></el-table-column>
        <el-table-column prop="name" label="名称" width="180">
        </el-table-column>
        <el-table-column prop="desc" label="描述">
        </el-table-column>
        <el-table-column align="center" width="50">
          <template #default="scope">
            <i class="el-icon-delete" style="cursor:pointer;" alt="移除角色" @click="handleRemoveRole(scope.row)"></i>
          </template>
        </el-table-column>
      </el-table>
    </el-form-item>
    <el-form-item>
      <el-button v-if="id" type="primary" @click="onSubmit(true)" v-waves>保存</el-button>
      <el-button v-if="!id" type="primary" @click="onSubmit(true)" v-waves>保存并继续</el-button>
      <el-button v-if="!id" type="primary" @click="onSubmit(false)" v-waves>保存并退出</el-button>
      <el-button v-waves @click="onCancel">取消</el-button>

      <filter-dialog ref="rolesTable" title="选择角色" v-model="selecteRoles.show" :rows="selecteRoles.rows" :total="selecteRoles.total" :index-column="true"
      :multiple-selected="true" width="80%" @page-info-change="handleRolePageInfoChange" :show-loading="selecteRoles.showLoading" 
      @close="handleRolesDialogClose" @selected="handleRolesSelected">
        <template #condition>
          <el-form-item>
            <el-input @keyup.enter="handleRolesFilter" placeholder="角色名称" v-model="selecteRoles.listQuery.condition.keyword" clearable></el-input>
          </el-form-item>
          <el-form-item>
            <el-button class="filter-item" style="margin-left: 10px;" type="primary" v-waves icon="el-icon-search" @click="handleRolesFilter">搜索</el-button>
          </el-form-item>
        </template>
        <template #default>
          <el-table-column min-width="150px" label="名称" align="center" sortable="custom" property="name">
            <template #default="scope">
              <span>{{scope.row.name}}</span>
            </template>
          </el-table-column>
          <el-table-column min-width="150px" label="描述" align="left" header-align="center">
            <template #default="scope">
              <span>{{scope.row.desc}}</span>
            </template>
          </el-table-column>
        </template>
      </filter-dialog>
    </el-form-item>
  </el-form>
</template>

<style scoped>
  .el-form {
    margin: 20px;
  }

</style>

<script>
import adminAddApi from '@/apis/admin/adminAdd'
import adminInfoApi from '@/apis/admin/adminInfo'
import waves from '@/directive/waves' // 水波纹指令
// import _ from 'underscore'
import filterDialog from '@/components/filterDialog/filterDialog'

export default {
  directives: {
    waves
  },
  props: ['id'],
  data () {
    return {
      admin: {
        id: null,
        account: '',
        name: '',
        password: '',
        state: 0,
        roles: []
      },
      listQuery: {
        condition: {
        },
        pageInfo:{
          pageIndex:1,
          pageSize:20,
          orders: []
        }
      },
      state: 0,
      formRules: {
        account: [{
          required: true,
          message: '请输入管理员账号'
        }, {
          max: 20,
          message: '账号长度需少于20个字符',
          trigger: 'change'
        }],
        name: [{
          required: true,
          message: '请输入管理员名称'
        }, {
          max: 10,
          message: '管理员名称长度需少于10个字符',
          trigger: 'change'
        },{
          pattern: /^[a-z0-9A-Z\u4e00-\u9fa5]+$/,
          message: '只能输入中文、数字、字母'
        }],
        password: [{
          required: true,
          message: '请输入管理员密码'
        }, {
          min: 6,
          max: 20,
          message: '密码长度在6到20个字符',
          trigger: 'change'
        }, {
          pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/,
          message: '密码必须是数字和字母组合'
        }]
      },
      selecteRoles: {
        show: false,
        showLoading: false,
        rows: [],
        total: 0,
        listQuery: {
          condition: {
            keyword: undefined
          },
          pageInfo:{
            pageIndex:1,
            pageSize:20,
            orders: []
          }
      },
      }
    }
  },
  watch: {
    id () {
      this.resetForm()
    }
  },
  components: {
    filterDialog
  },
  methods: {
    /**
       * 提交数据
       * @param {*} goon 新增后是否继续新增
       */
    onSubmit (goon) {
      this.$refs.form.validate().then(response => {
        if (!response) {
          return
        }
        if (this.admin.roles && this.admin.roles.length > 0) {
          this.admin.roleIds = this.admin.roles.map(role => {
            return role.roleId
          })
        }
        this.admin.enabled = this.state === 1 ? 'YES' : 'NO'
        this.admin.state = this.state
        if (!this.id) {
          adminAddApi.insert(this.admin).then(response => {
            this.$message({
              title: '成功',
              message: '管理员创建成功',
              type: 'success'
            })
            if (goon) {
              this.resetForm()
            } else {
              this.$router.replaceBy(this.$router.routesConfig.adminList)
            }
          })
        } else {
          adminInfoApi.update(this.admin).then(response => {
            this.$message({
              title: '成功',
              message: '管理员更新成功',
              type: 'success'
            })
            this.$router.replaceBy(this.$router.routesConfig.adminList)
          })
        }
      })
    },
    /**
       * 取消按钮处理
       */
    onCancel () {
      this.$router.replaceBy(this.$router.routesConfig.adminList)
    },
    /**
       * 加载角色数据
       * @param {*} pageInfo 分页信息
       */
    getRoleList (pageInfo) {
      this.selecteRoles.showLoading = true
      if (this.id) {
        adminInfoApi.getRolePage({ pageInfo, condition: this.selecteRoles.listQuery.condition }).then(response => {
          this.selecteRoles.rows = response.data.content
          this.selecteRoles.total = response.data.totalElements
          this.selecteRoles.showLoading = false
        }).catch(response => {
          this.selecteRoles.showLoading = false
        })
      } else {
        adminAddApi.getRolePage({ pageInfo, condition: this.selecteRoles.listQuery.condition }).then(response => {
          this.selecteRoles.rows = response.data.content
          this.selecteRoles.total = response.data.totalElements
          this.selecteRoles.showLoading = false
        }).catch(response => {
          this.selecteRoles.showLoading = false
        })
      }
    },
    /**
       * 获取管理员信息
    */
    getAdmin () {
      if (this.id) {
        adminInfoApi.getById(this.id).then(response => {
          this.admin = response.data
          this.admin.state = response.data.enabled === 'YES' ? 1 : 0
          this.state = this.admin.state
        })
      }
    },
    /**
       * 重置表单
       */
    resetForm () {
      this.$refs.form.resetFields()
      this.admin.id = null
      this.admin.roles = []
      this.selecteRoles.rows = []
      this.selecteRoles.total = 0
      this.selecteRoles.listQuery.condition.keyword = undefined
    },
    /**
       * 选择角色
       */
    handleSelectRoles () {
      this.selecteRoles.show = true
    },
    /**
       * 移除角色
       */
    handleRemoveRole (role) {
      this.admin.roles = this.admin.roles.filter(ar => {
        return !(ar.id === role.id)
      })
    },
    /**
       * 过滤角色
       */
    handleRolesFilter (pageInfo) {
      this.$refs.rolesTable.changeCondition()
    },
    /**
       * 角色选择
       */
    handleRolesSelected (roles) {
      roles.forEach(r => {
        if (!this.admin.roles.find(ar => {
          return ar.id === r.id
        })) {
          this.admin.roles.push(r)
        }
      })
    },
    /**
       * 选择角色窗口关闭
       */
    handleRolesDialogClose () {
      this.selecteRoles.listQuery.condition.name = undefined
      this.selecteRoles.rows = []
      this.selecteRoles.show = false
    },
    /**
       * 查询角色条件变更
       */
    handleRolePageInfoChange (pageInfo) {
      this.getRoleList(pageInfo)
    }
  },
  created () {
    this.getAdmin()
  }
}

</script>
